import React, { useState, useRef } from "react";
import * as classes from "./ContactForm.module.css";
import useInputValidation from "../../hooks/use-input-validation";

const ContactForm = () => {

  const [sent, setIsSent] = useState(false)
  
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInputValidation((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInputValidation((value) => value.includes("@"));

  const [enteredRole, setEnteredRole] = useState(null)

  const changeRoleHandler = (event) => {
    setEnteredRole(event.target.value)
  }

  const [enteredMessage, setEnteredMessage] = useState('')

  const changeMessageHandler = (event) => {
    setEnteredMessage(event.target.value)
    console.log(enteredMessage)
  }

  let formIsValid = false;

  if (enteredNameIsValid && enteredEmailIsValid) {
    formIsValid = true;
  }

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const sumbitHandler = (event) => {
    if (!enteredNameIsValid || !enteredEmailIsValid) {
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ 
        "form-name": "contact",
        "name": enteredName,
        "email": enteredEmail,
        "role": enteredRole,
        "message": enteredMessage
      })
    })
    .then(() => {
      console.log("Message Sent!")
      setIsSent(true)
    })
    .catch((error) => alert(error));

    event.preventDefault();
      
  };

  return (
    <section className={classes.section}>
      <div>
        <h1 className="header-decoration">{!sent ? 'Contact Me' : 'Thanks!'}</h1>
        <p>{!sent ? "Fill out the form below and I'll get back to you as soon as possible." : "I'll get back to you as soon as possible"}</p>
        {!sent &&
        <form onSubmit={sumbitHandler}>
          <h2>Get in Touch</h2>
          {/* You still need to add the hidden input with the form name to your JSX form */}
          <input type="hidden" name="form-name" value="contact" />
          {/* Custom Form */}
          <div className={classes.formContainer}>
            <div className={classes.inputContainer}>
              <label htmlFor="name">Your Name</label>
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Enter your name"
                onChange={nameChangedHandler}
                onBlur={nameBlurHandler}
                value={enteredName}
                required
              />
              {nameInputHasError && <p className={classes.errorText}>Name must not be empty</p>}
            </div>
            <div className={classes.inputContainer}>
              <label htmlFor="email">Your Email</label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={emailChangedHandler}
                onBlur={emailBlurHandler}
                value={enteredEmail}
                required
              />
              {emailInputHasError && <p className={classes.errorText}>Enter a valid email</p>}
            </div>
            <div className={classes.inputContainer}>
              <label htmlFor="role">Your Role</label>
              <select
                id="role"
                name="role"
                onChange={changeRoleHandler}
                multiple
              >
                <option value="Recruiter">Recruiter</option>
                <option value="Company">Company</option>
                <option value="Individual">Individual</option>
                <option value="Studio">Studio</option>
              </select>
            </div>
            <div className={classes.inputContainer}>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter a message"
                value={enteredMessage}
                onChange={changeMessageHandler}
                required
              ></textarea>
            </div>
          </div>
          <div>
           <button className={classes.button} type="submit">Send</button>
          </div>
        </form>
        }
      </div>
    </section>
  );
};

export default ContactForm;
