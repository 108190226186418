import React from "react";
import Layout from "../components/ui/Layout";
import ContactForm from "../components/ContactForm/ContactForm";

const Contact = () => {
  return (
    <Layout title="Contact">
      <ContactForm />
    </Layout>
  );
};

export default Contact;
